globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"YtZiU6vSiPJnSNMo96b-Z"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import { commonConfig } from "@cyna/common/config"
import { webConfig } from "@cyna/web/config"
import * as Sentry from "@sentry/nextjs"

Sentry.init({
  dsn: webConfig.sentry.dsn,

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  enabled: commonConfig.env.isProduction || commonConfig.env.isCI,
})
